import React from "react";

import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
//import { Helmet } from "react-helmet";
import YouTube from "react-youtube";

class WebinarOne extends React.Component {
    render() {
        const opts = {
            height: "390",
            width: "640",
            playerVars: {
                autoplay: 1,
                modestbranding: 1,
                rel: 0,
            },
        };
        return <YouTube videoId="yP8noF2fyMs" opts={opts} onReady={this._onReady} />;
    }

    _onReady(event) {
        event.target.pauseVideo();
    }
}

class WebinarTwo extends React.Component {
    render() {
        const opts = {
            height: "390",
            width: "640",
            playerVars: {
                autoplay: 1,
                modestbranding: 1,
                rel: 0,
            },
        };
        return <YouTube videoId="sofOu4iOnTs" opts={opts} onReady={this._onReady} />;
    }

    _onReady(event) {
        event.target.pauseVideo();
    }
}

class WebinarForm extends React.Component {
    initHubspotForm() {
        if ("hbspt" in window) {
            window.hbspt.forms.create({
                portalId: "5502534",
                formId: "3c3b67db-7fb3-4be4-99fa-7b34c0443b5a",
                target: "#webinar-form-container",
            });
            const formContainer = document.getElementById("webinar-form-container");
            formContainer.classList.remove("webinar-heighthack");
        } else {
            setTimeout(this.initHubspotForm, 200);
        }
    }
    componentDidMount() {
        this.initHubspotForm();
    }
    render() {
        return (
            <>
                <section className="hero is-primary is-small">
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <h1 className="title has-text-warning">Webinars</h1>
                            <p className="is-size-5">Sign up for upcoming webinars, or catch up on one you missed.</p>
                        </div>
                    </div>
                </section>
                {/*}
                <div className="webinar-heighthack" id="webinar-form-container">
                    <Helmet>
                        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
                    </Helmet>
                </div>
                */}
                <section className="hero">
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column">
                                    <div className="content m-l-lg has-text-left">
                                        <h2 className="title">Ultra Low Variant Detection: Du Novo Sequencing</h2>
                                        <p>
                                            Du Novo Sequencing can achieve sub-1% variant detection, which improves on
                                            other Duplex Sequencing analysis tools by removing reliance on a reference
                                            sequence, preserving a higher proportion of the input reads, and being
                                            available for Galaxy. We demonstrate the application of this approach by
                                            validating rare variants in the human mitochondrial genome. Dr. Barbara
                                            Arbeithuber (Johannes Kepler University Linz, Center for Medical Research)
                                            and Dr. Nick Stoler (Penn State University) will present the method and
                                            applications in this webinar.
                                        </p>
                                    </div>
                                </div>
                                <div className="column is-half p-lg">
                                    <WebinarOne></WebinarOne>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="hero">
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column">
                                    <div className="content m-l-lg has-text-left">
                                        <h2 className="title">Structural Variant Detection using ONT Data</h2>
                                        <p>
                                            Long-read sequencing allows for sensitive and accurate detection of
                                            structural variants (SV) which are critical to research in genomic
                                            applications such as cancer genomics, genetic disease, and crop
                                            domestication and improvement. Dr. Nathan Roach (GalaxyWorks) will
                                            demonstrate analysis methods of long read data using the Oxford Nanopore
                                            (ONT) tool suite in Galaxy Pro. Mr. Michael Alonge (Johns Hopkins
                                            University) will highlight his work analyzing 100 diverse tomato accessions
                                            to established the largest comprehensive database of SVs in any crop.
                                        </p>
                                    </div>
                                </div>
                                <div className="column is-half p-lg">
                                    <WebinarTwo></WebinarTwo>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="hero is-primary is-small">
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <h2 className="title is-size-5">
                                More coming soon, but if you&apos;re interested in particular topics or upcoming events
                                please contact us and let us know.
                            </h2>
                            <Link className="button is-warning" to="/contact">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const WebinarPage = () => (
    <Layout>
        <SEO title="Webinars" />
        <section className="hero is-primary is-fullheight-with-navbar">
            <div className="hero-body">
                <div className="container">
                    <div className="container has-text-centered">
                        <WebinarForm></WebinarForm>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default WebinarPage;
